import { Outlet } from 'react-router-dom'
import Appbar from '../Components/Appbar/Appbar'
import { useEffect } from 'react'
import Storage from '../Services/Storage'
import { DetailClockApp } from '../Services/Swagger'
import moment from 'moment'
import 'moment-timezone'

export default function () {
  useEffect(() => {
    const company: DetailClockApp = Storage.getItem('CURRENT_COMPANY')
    moment.locale('de')
    if (company) {
      moment.tz.setDefault(company.timezone)
    }
  })
  return (
    <>
      <Appbar />
      <Outlet />
    </>
  )
}

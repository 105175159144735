import { Box, CircularProgress, Container, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Search, UserCard } from '../../Components'
import { CompanyHelper } from '../../Helpers'
import Storage from '../../Services/Storage'
import {
  ClockAppResponse,
  ClockappsApi,
  DetailClockApp,
  FormFilterClockApp,
} from '../../Services/Swagger'
import { Colors } from '../../Services/Themes/TitanTheme'
import Verification from './Dialogs/Verification'

export default function () {
  const company: DetailClockApp = Storage.getItem('CURRENT_COMPANY')
  const [modalVerify, setModalVerify] = React.useState<{
    value: any
    visible: boolean
    employee?: ClockAppResponse
  }>({
    value: undefined,
    visible: false,
    employee: undefined,
  })

  const [filter, setFilter] = useState<FormFilterClockApp>({
    search: '',
    departments: [],
  })

  const { data, refetch, isLoading } = useQuery(
    ['/api/clockapp/searchDataClockApp'],
    async () => {
      const response = await new ClockappsApi().searchDataClockApp(filter)
      return response.data
    },
    {
      enabled: false,
    },
  )
  const onClick = (user: any) => {
    setModalVerify({
      value: '',
      visible: true,
      employee: user,
    })
  }

  useEffect(() => {
    if (filter.departments && filter.departments?.length > 0) refetch()
  }, [filter])

  useEffect(() => {
    setFilter({
      ...filter,
      departments: new CompanyHelper().cleanDeparments(company.departments),
    })
    const settingValue = company.setting?.value as any
    setModalVerify({
      ...modalVerify,
      value: settingValue?.timeclock_verify_employee.value,
    })
  }, [])

  const onEnter = (q: any) => {
    setFilter({
      ...filter,
      search: q,
    })
  }

  return (
    <Box sx={{ background: Colors.backdropBg }}>
      <Search onEnter={onEnter} />
      <Container maxWidth={false} sx={{ pb: 5 }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          {isLoading && <CircularProgress />}
          {!isLoading &&
            data?.map((user) => {
              return (
                <Grid item key={user.employee_id}>
                  <UserCard user={user} onClick={onClick} />
                </Grid>
              )
            })}
        </Grid>
      </Container>
      {modalVerify.visible && (
        <Verification
          isOpen={modalVerify.visible}
          employee={modalVerify.employee}
          onClose={() => {
            refetch()
            setModalVerify({
              value: undefined,
              visible: false,
              employee: undefined,
            })
          }}
        />
      )}
    </Box>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import { Box, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { ClockAppResponse } from '../../Services/Swagger'
import { TimeInput, WorkTypeSelect } from '..'
import { CompanyHelper } from '../../Helpers'

interface ClockOutProps {
  onSuccess: (timesheet: ClockOutResponse) => void
  onClickTimeSheet: () => void
  employee?: ClockAppResponse
  type: 'ClockIn' | 'ClockOut'
}

export interface ClockOutResponse {
  shift_type_id: number
  date: number
  time: string
  unix: number
}

const clockin = require('../../Assets/Images/clockin.svg').default
const clockout = require('../../Assets/Images/clockout.svg').default
const edit = require('../../Assets/Images/edit.svg').default
const calendar = require('../../Assets/Images/calendar.svg').default

export default function ClockInOut({ onSuccess, onClickTimeSheet, type, employee }: ClockOutProps) {
  const { t } = useTranslation()
  const [timesheet, setTimesheet] = React.useState<ClockOutResponse>({
    shift_type_id: Number(employee?.lastRegistrationTime?.shift_type_id),
    date: Number(employee?.lastRegistrationTime?.check_in_time ?? moment().unix()),
    time: moment(new Date()).format('HH:mm'),
    unix: moment().unix(),
  })
  const secondsIn24Hours = moment.duration(24, 'hours').asSeconds()
  const onSubmit = () => {
    const checkTime = new CompanyHelper().customMoment(timesheet.date, timesheet.time)
    if (type === 'ClockOut' && employee?.lastRegistrationTime?.check_in_time) {
      if (checkTime - employee?.lastRegistrationTime?.check_in_time >= secondsIn24Hours) {
        alert(t('01_01_shift_out_of_date'))
        return
      }
    }
    onSuccess({
      ...timesheet,
      unix: checkTime,
    })
  }

  const calendarIcon = () => {
    return <img src={calendar} />
  }

  return (
    <>
      <Box sx={{ textAlign: 'center', pb: 10 }}>
        <Typography variant='subtitle1'>{t('15_04_welcome_back')}</Typography>
        <Typography variant='subtitle1' sx={{ color: 'primary.main' }}>
          {employee?.fullname}
        </Typography>
      </Box>
      <DialogContent>
        <Typography variant='body2' sx={{ mb: 3 }}>
          {t('30_00_work_type')}
        </Typography>
        <WorkTypeSelect
          disabled={type === 'ClockOut'}
          value={timesheet.shift_type_id.toString()}
          onChange={(event) => {
            setTimesheet({
              ...timesheet,
              shift_type_id: Number(event.target.value),
            })
          }}
        />
        <Typography variant='body2' sx={{ mb: 3 }}>
          {t('20_13_date')}
        </Typography>
        <DatePicker
          value={moment.unix(timesheet.date).toDate()}
          components={{
            OpenPickerIcon: calendarIcon,
          }}
          maxDate={moment().add(31, 'days')}
          minDate={moment().add(-31, 'days')}
          onChange={(newValue) => {
            const unix = moment(newValue).unix()
            setTimesheet({
              ...timesheet,
              date: unix,
            })
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        <Typography variant='body2' sx={{ mb: 3 }}>
          {t('01_01_time_label')}
        </Typography>
        <TimeInput
          value={timesheet.time}
          onChange={(event) => {
            setTimesheet({
              ...timesheet,
              time: event.target.value,
            })
          }}
        />
        <Button fullWidth variant='contained' color='primary' sx={{ mb: 6 }} onClick={onSubmit}>
          {type === 'ClockOut' && (
            <>
              <img src={clockout} alt='logo' height={24} style={{ marginRight: 12 }} />
              {t('00_06_clockout')}
            </>
          )}
          {type === 'ClockIn' && (
            <>
              <img src={clockin} alt='logo' height={24} style={{ marginRight: 12 }} />
              {t('00_06_clockin')}
            </>
          )}
        </Button>
        <Button fullWidth onClick={onClickTimeSheet}>
          <img src={edit} alt='logo' height={24} style={{ marginRight: 12 }} />
          <Typography component={'a'} sx={{ fontWeight: '500', textAlign: 'center' }}>
            {t('02_00_my_timesheets')}
          </Typography>
        </Button>
      </DialogContent>
    </>
  )
}

import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { ClockappsApi, FormCheckCompanyIP, ResponseCheckCompanyIP } from '../../Services/Swagger'
import { CircularProgress, Container, Grid, Typography } from '@mui/material'
import Storage from '../../Services/Storage'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { CompanyHelper } from '../../Helpers'
import globalAxios from 'axios'
import { CompanyCard } from '../../Components'
import { useTranslation } from 'react-i18next'
import 'moment-timezone'

export default function () {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<FormCheckCompanyIP>({
    ip: '',
    token: '',
    min_time: moment().startOf('day').unix(),
    max_time: moment().endOf('day').unix(),
  })

  const [isClicked, setIsClicked] = useState(false)

  const navigate = useNavigate()
  const { data, refetch, isLoading, error } = useQuery(
    ['/api/clockapp/checkCompanyIP', filter],
    async () => {
      try {
        const response = await new ClockappsApi().checkCompanyIP(filter, undefined, {
          headers: {
            app: 'TimeClockHorsens',
          },
        })
        return response.data
      } catch {
        alert(t('20_00_server_return_invalid_data_format'))
      } finally {
        !isLoading
      }
    },
    {
      enabled: false,
    },
  )
  const init = async () => {
    try {
      Storage.clear()
      globalAxios.defaults.headers.common = {}
      const ip = await new CompanyHelper().getMyIpAddress()
      setFilter({
        ...filter,
        ip: ip,
      })
    } catch (ex: any) {
      alert(ex)
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (filter.ip) refetch()
  }, [filter])

  const processCompanyData = async (company: ResponseCheckCompanyIP) => {
    try {
      Storage.setItem('APP_TOKEN', company.token)
      const companyResponse = await new ClockappsApi().detailClockApp()
      if (companyResponse.data) {
        Storage.setItem('CURRENT_COMPANY', companyResponse.data)
        moment.tz.setDefault(companyResponse.data.timezone)
        navigate('/company')
      } else {
        alert(t('20_00_server_return_invalid_data_format'))
      }
    } catch (ex: any) {
      alert(ex)
    } finally {
      setIsClicked(false)
    }
  }

  useEffect(() => {
    if (data && data.length == 1) {
      processCompanyData(data[0])
    }
  }, [data])

  const onClick = (company: ResponseCheckCompanyIP) => {
    if (isClicked) {
      return
    }
    setIsClicked(true)
    processCompanyData(company)
  }

  if (error instanceof Error) {
    return <Typography>{t('20_00_server_return_invalid_data_format')}</Typography>
  }
  return (
    <>
      <Container maxWidth={false} sx={{ pb: 5 }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center', marginTop: 10 }}>
          {data?.length === 0 && <Typography>{t('12_01_no_company_registered')}</Typography>}
          {isLoading && <CircularProgress />}
          {!isLoading &&
            data?.map((company) => {
              return (
                <Grid item key={company.company_id}>
                  <CompanyCard company={company} onClick={onClick} />
                </Grid>
              )
            })}
        </Grid>
      </Container>
    </>
  )
}

import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
const options = {
  type: 'backend',
  crossDomain: false,
  allowMultiLoading: false,
  loadPath: 'https://backend.titanplan.com/i18n/translate/getTranslations/{{lng}}?json=true',
}

const language = 'da'

i18n.use(HttpApi).init({
  backend: options,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  debug: false,
  lng: language,
  fallbackLng: language,
})

export default i18n

import { TextField, TextFieldProps } from '@mui/material'
import * as React from 'react'
import { IMaskInput } from 'react-imask'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask='00:00'
      inputRef={ref as any}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

export default function TimeInput({ value, onChange, ...otherProps }: TextFieldProps) {
  return (
    <TextField
      value={value}
      onChange={onChange}
      fullWidth
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
      {...otherProps}
    />
  )
}

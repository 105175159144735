import axios from 'axios'
import { t } from 'i18next'
import _ from 'lodash'
import moment from 'moment'
import { DepartmentDetail } from '../Services/Swagger'

export class CompanyHelper {
  cleanDeparments(departments?: DepartmentDetail[]) {
    const resultDepartment = _.map(departments, (item) => ({
      department_id: item.department_id,
      groups: _.map(item.groups, (group) => ({ group_id: group.group_id })),
    }))
    return resultDepartment
  }
  async getMyIpAddress() {
    const { data } = await axios.get('https://www.cloudflare.com/cdn-cgi/trace', { headers: {} })
    const dataObj = data
      .trim()
      .split('\n')
      .reduce((obj: any, pair: any) => {
        pair = pair.split('=')
        return (obj[pair[0]] = pair[1]), obj
      }, {})
    return dataObj.ip
  }

  showDate = () => {
    return moment(new Date()).format('MMMM DD YYYY')
  }
  formatDateTime = (date: any) => {
    return moment(date).format('HH:mm')
  }

  stringAvatar(name: any) {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    }
  }

  getWorkStatus = (working_status: any) => {
    if (working_status === -1) {
      return t('20_03_not_working')
    } else if (working_status === 0) {
      return t('20_03_working')
    } else if (working_status === 1) {
      return t('20_03_not_working')
    } else if (working_status === 2) {
      return t('20_03_not_working')
    }
  }
  customMoment = (data: number, time: string) => {
    return moment
      .unix(data)
      .set('hour', Number(time.split(':')[0]))
      .set('minute', time.split(':')[1] ? Number(time.split(':')[1]) : 0)
      .unix()
  }
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { TimeSheetDetail2 } from '../../Services/Swagger'
import { TimeInput, WorkTypeSelect } from '..'
import { useState } from 'react'
import { CompanyHelper } from '../../Helpers'

interface TimeSheetEditFormProps {
  onSuccess: (timesheet: TimeSheetDetail2) => void
  timesheet: TimeSheetDetail2
  onBack: () => void
}

interface DataProps {
  shift_type_id: number
  clockInUnix: number
  clockInTime: string
  clockOutUnix: number
  clockOutTime: string
}
const backIcon = require('../../../src/Assets/Images/arrowBack.svg').default
const calendar = require('../../Assets/Images/calendar.svg').default
export default function TimeSheetEditForm({
  onSuccess,
  timesheet,
  onBack,
}: TimeSheetEditFormProps) {
  const { t } = useTranslation()
  const [data, setData] = useState<DataProps>({
    shift_type_id: timesheet.shift_type_id ?? 0,
    clockInUnix: timesheet.check_in_time ?? 0,
    clockInTime: moment.unix(timesheet.check_in_time ?? 0).format('HH:mm'),
    clockOutUnix: timesheet.check_out_time ?? timesheet.check_in_time ?? 0,
    clockOutTime: moment.unix(timesheet.check_out_time ?? 0).format('HH:mm'),
  })
  const onClick = () => {
    const checkInTime = new CompanyHelper().customMoment(data.clockInUnix, data.clockInTime)
    const checkOutTime = new CompanyHelper().customMoment(data.clockOutUnix, data.clockOutTime)
    const unixTimestamp24h = moment.duration(24, 'hours').asSeconds()
    if (checkOutTime - checkInTime < unixTimestamp24h) {
      onSuccess({
        ...timesheet,
        shift_type_id: data.shift_type_id,
        check_in_time: checkInTime,
        check_out_time: checkOutTime,
      })
    } else {
      alert(t('01_01_shift_out_of_date'))
    }
  }
  const calendarIcon = () => {
    return <img src={calendar} />
  }
  return (
    <>
      <img
        onClick={() => {
          onBack()
        }}
        src={backIcon}
        alt='backIcon'
        height={36}
        style={{ position: 'absolute', left: '15%', top: '9%', cursor: 'pointer' }}
      />
      <Box sx={{ textAlign: 'center', pb: 10 }}>
        <Typography variant='subtitle1'>{t('20_23_edit_timesheet')}</Typography>
      </Box>
      <DialogContent>
        <Typography variant='body2' sx={{ mb: 3 }}>
          {t('30_00_work_type')}
        </Typography>
        <WorkTypeSelect
          value={data.shift_type_id?.toString()}
          onChange={(event) => {
            setData({
              ...data,
              shift_type_id: Number(event.target.value),
            })
          }}
        />
        <Typography variant='body2' sx={{ mb: 3 }}>
          {t('00_06_clockin')}
        </Typography>
        <Grid container spacing={4} columns={12}>
          <Grid item xs={6}>
            <DatePicker
              value={moment.unix(data.clockInUnix ?? 0).toDate()}
              components={{
                OpenPickerIcon: calendarIcon,
              }}
              minDate={moment().add(-31, 'days')}
              maxDate={moment().add(31, 'days')}
              onChange={(newValue) => {
                const unix = moment(newValue).unix()
                setData({
                  ...data,
                  clockInUnix: unix,
                })
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>

          <Grid item xs={6}>
            <TimeInput
              value={data.clockInTime}
              onChange={(event) => {
                setData({
                  ...data,
                  clockInTime: event.target.value,
                })
              }}
            />
          </Grid>
        </Grid>
        <Typography variant='body2' sx={{ mb: 3 }}>
          {t('00_06_clockout')}
        </Typography>
        <Grid container spacing={4} columns={12}>
          <Grid item xs={6}>
            <DatePicker
              value={moment.unix(data.clockOutUnix ?? 0).toDate()}
              components={{
                OpenPickerIcon: calendarIcon,
              }}
              minDate={moment().add(-31, 'days')}
              maxDate={moment().add(31, 'days')}
              onChange={(newValue) => {
                const unix = moment(newValue).unix()
                setData({
                  ...data,
                  clockOutUnix: unix,
                })
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <TimeInput
              value={data.clockOutTime}
              onChange={(event) => {
                setData({
                  ...data,
                  clockOutTime: event.target.value,
                })
              }}
            />
          </Grid>
        </Grid>
        <Button fullWidth variant='contained' color='primary' sx={{ mb: 6 }} onClick={onClick}>
          {t('00_03_save')}
        </Button>
      </DialogContent>
    </>
  )
}

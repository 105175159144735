import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Avatar, CardActionArea } from '@mui/material'
import { ResponseCheckCompanyIP } from '../../Services/Swagger'

interface Props {
  company: ResponseCheckCompanyIP
  onClick: (data: any) => void
}
export default function companyCard({ company, onClick }: Props) {
  return (
    <Card
      sx={{
        display: 'flex',
        height: 240,
        boxShadow: 'none',
        borderRadius: 2,
        width: 200,
        border: 1,
        borderColor: 'rgba(0, 0, 0, 0.1)',
      }}
      onClick={() => onClick(company)}
    >
      <CardActionArea sx={{ textAlign: 'center' }}>
        <Avatar
          sx={{
            mx: 'auto',
            height: 64,
            width: 64,
          }}
          src={company.logo_url}
        />
        <CardContent>
          <Typography gutterBottom variant='body1' component='div' sx={{ fontWeight: 500 }}>
            {company.company_name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  verify_code: Yup.string()
    .min(6, '00_06_password_at_least_6_characters')
    .required('20_01_password_required'),
})

export const ValidationClockIn = Yup.object().shape({
  check_in_time: Yup.string().required(),
  shift_type_id: Yup.number().required(),
  employee_id: Yup.string().required(),
  group_id: Yup.string().required(),
})

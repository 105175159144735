export default {
  setItem: (key: string, value: any) => {
    let val
    try {
      val = JSON.stringify(value)
    } catch (ex: any) {
      val = value
    }
    return localStorage.setItem(key, val)
  },
  getItem: (key: string) => {
    const item = localStorage.getItem(key)
    if (item) {
      try {
        return JSON.parse(item)
      } catch (ex: any) {
        return item
      }
    } else {
      return null
    }
  },
  removeItem: (key: string) => {
    return localStorage.removeItem(key)
  },
  updateItem: (key: string, value: any) => {
    const item = localStorage.getItem(key)
    let result = value
    if (item) {
      result = { ...JSON.parse(item), ...value }
    }
    return localStorage.setItem(key, JSON.stringify(result))
  },
  clear: () => localStorage.clear(),
}

import React, { useState } from 'react'
import { Box, Button, DialogActions, DialogContent, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import {
  ClockAppResponse,
  ClockappsApi,
  FormVerifyEmployeeClockAppp,
  ReponseVerifyEmployeeClockApp,
} from '../../Services/Swagger'
import { useFormik } from 'formik'
import { ValidationSchema } from '../../Schemas'
import Storage from '../../Services/Storage'

interface VerifyPasswordProps {
  employee?: ClockAppResponse
  onSuccess: (employeeProfile: ReponseVerifyEmployeeClockApp) => void
}

export default function VerifyPassword({ employee, onSuccess }: VerifyPasswordProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [params] = React.useState<FormVerifyEmployeeClockAppp>({
    employee_id: employee?.employee_id,
    verify_code: '',
    verify_type: 0,
  })

  const { values, errors, handleChange, isValid, handleSubmit } = useFormik({
    initialValues: params,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      verifyEmployee(values)
    },
  })

  const verifyEmployee = async (formData: FormVerifyEmployeeClockAppp) => {
    if (!formData.verify_code) return
    setIsLoading(true)
    try {
      const response = await new ClockappsApi().verifyEmployeeClockApp(formData)
      Storage.setItem('APP_TOKEN', response.data.token)
      onSuccess(response.data)
    } catch (ex: any) {
      alert(t(ex.response.statusText))
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ textAlign: 'center', pb: 10 }}>
        <Typography sx={{ flex: '1', color: 'primary.main' }} variant='subtitle1'>
          {t('00_06_verification')}
        </Typography>
        <Typography variant='h3' sx={{ fontWeight: '600', mb: 2 }}>
          {`${t('15_04_welcome_back')} ${employee?.fullname}`}
        </Typography>
        <Typography variant='h3'>{t('25_00_password_verify')}</Typography>
      </Box>
      <DialogContent>
        <TextField
          fullWidth
          variant='outlined'
          name='password'
          type='password'
          inputRef={(input) => input && input.focus()}
          value={values.verify_code}
          onChange={handleChange('verify_code')}
          error={!!errors.verify_code}
          helperText={t(errors.verify_code ?? '')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          type='submit'
          variant='contained'
          color='primary'
          disabled={!isValid || isLoading}
          sx={{ mb: 10 }}
        >
          {t('20_45_verify')}
        </Button>
      </DialogActions>
    </form>
  )
}

import * as React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Box, Container, Typography } from '@mui/material'
import moment from 'moment'
import { ComponentTimeSheet } from '../ComponentTimeSheet/ComponentTimeSheet'
import {
  ClockAppResponse,
  EmployeeTimeSheet2,
  FormFilterListTimeSheetApproveEmployeeView,
  TimeSheetApi,
  TimeSheetDetail2,
} from '../../Services/Swagger'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditForm from './TimeSheetEditForm'

interface TimeSheetsProps {
  employee?: ClockAppResponse
}

const calendar = require('../../Assets/Images/calendar.svg').default
export default function TimeSheets({ employee }: TimeSheetsProps) {
  const { t } = useTranslation()

  const [editTimesheetObj, setEditTimesheetObj] = useState<{
    visible: boolean
    timesheet: TimeSheetDetail2
  }>({
    visible: false,
    timesheet: {},
  })
  const [filter, setFilter] = useState<FormFilterListTimeSheetApproveEmployeeView>({
    is_approved: -1,
    min_time: moment().startOf('month').unix(),
    max_time: moment().endOf('month').unix(),
    employees: [
      {
        employee_id: employee?.employee_id,
      },
    ],
  })

  const { data, refetch } = useQuery(
    ['/api/timeregistration/getListRegistrationApproveEmployeeView'],
    async () => {
      const response = await new TimeSheetApi().getListRegistrationApproveEmployeeView(filter || {})
      return response.data
    },
    {
      enabled: false,
    },
  )
  const isNoData = (timesheets: any) => {
    if (!timesheets) {
      return true
    }
    let count = 0
    timesheets.map((record: EmployeeTimeSheet2) => {
      return record.days?.map((day) => {
        count += Number(day.registrations?.length)
      })
    })
    return count === 0
  }

  useEffect(() => {
    if (filter) refetch()
  }, [filter])

  const onClick = (timesheet: TimeSheetDetail2) => {
    setEditTimesheetObj({
      visible: true,
      timesheet: timesheet,
    })
  }
  const updateTimeSheet = async (timesheet: TimeSheetDetail2) => {
    try {
      await new TimeSheetApi().updateTimeSheet(
        timesheet.registration_time_approve_id ?? 0,
        timesheet,
      )
      refetch()
      setEditTimesheetObj({
        visible: false,
        timesheet: {},
      })
    } catch (ex: any) {
      alert(t(ex.response.statusText))
    }
  }
  const onBack = () => {
    setEditTimesheetObj({
      visible: false,
      timesheet: {},
    })
  }
  const calendarIcon = () => {
    return <img src={calendar} />
  }

  return (
    <>
      {!editTimesheetObj.visible && (
        <>
          <Box sx={{ textAlign: 'center', pb: 10 }}>
            <Typography variant='subtitle1'> {t('15_04_welcome_back')},</Typography>
            <Typography variant='subtitle1' sx={{ color: 'primary.main' }}>
              {employee?.fullname}
            </Typography>
            <Typography variant='subtitle2'>{t('02_00_my_timesheets')}</Typography>
          </Box>
          <Container maxWidth={false} sx={{ overflowY: 'scroll' }}>
            <DatePicker
              views={['month', 'year']}
              components={{
                OpenPickerIcon: calendarIcon,
              }}
              value={moment.unix(filter.min_time ?? 0)}
              onChange={(newValue) => {
                const min = moment(newValue).startOf('month').unix()
                const max = moment(newValue).endOf('month').unix()
                setFilter({
                  ...filter,
                  min_time: min,
                  max_time: max,
                })
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    style={{ border: 'none', fontSize: 15, width: 85, outline: 'none' }}
                    ref={inputRef}
                    {...inputProps}
                  />
                  {InputProps?.endAdornment}
                </Box>
              )}
            />
            <Box sx={{ mt: 4 }}>
              {isNoData(data) && (
                <Typography variant='body2'>{t('20_00_no_data_found')}</Typography>
              )}
              {!isNoData(data) &&
                data &&
                data.length > 0 &&
                data.map((record: EmployeeTimeSheet2) => {
                  return record.days?.map((day) => {
                    return day.registrations?.map((timesheet) => {
                      return (
                        <ComponentTimeSheet
                          onClick={() => onClick(timesheet)}
                          timesheet={timesheet}
                          key={timesheet.registration_time_id}
                        />
                      )
                    })
                  })
                })}
            </Box>
          </Container>
        </>
      )}
      {editTimesheetObj.visible && (
        <EditForm
          onSuccess={updateTimeSheet}
          timesheet={editTimesheetObj.timesheet}
          onBack={onBack}
        />
      )}
    </>
  )
}

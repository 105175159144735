import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Colors } from '../../Services/Themes/TitanTheme'
import { CompanyHelper } from '../../Helpers'

export function Clock() {
  const [timeString, setTimeString] = useState('00:00')

  useEffect(() => {
    setInterval(() => {
      const now = new Date()
      const newTimeString = new CompanyHelper().formatDateTime(now)
      setTimeString(newTimeString)
    }, 1000)
  }, [])

  return (
    <Box>
      <Typography variant='h3' sx={{ color: Colors.white }}>
        {new CompanyHelper().showDate()}
      </Typography>
      <Typography variant='h1' sx={{ color: Colors.white }}>
        {timeString}
      </Typography>
    </Box>
  )
}

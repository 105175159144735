import { Box, Grid, Typography } from '@mui/material'
import { Colors } from '../../Services/Themes/TitanTheme'
import { TimeSheetDetail2 } from '../../Services/Swagger'
import moment from 'moment'
const edit = require('../../../src/Assets/Images/edit.svg').default
interface Props {
  timesheet: TimeSheetDetail2
  onClick: () => void
}
export function ComponentTimeSheet({ timesheet, onClick }: Props) {
  return (
    <Box
      sx={{
        borderBottom: 1,
        py: 2,
        borderColor: Colors.gray100,
        textAlign: 'left',
      }}
    >
      <Grid container spacing={2} columns={20} sx={{ alignItems: 'center' }}>
        <Grid item xs={5}>
          <Typography sx={{ fontWeight: 500, alignItems: 'center' }} variant='body1'>
            {moment.unix(timesheet.check_in_time ?? 0).format('DD-MM-YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ fontWeight: 500, alignItems: 'center' }} variant='body1'>
            {moment.unix(timesheet.check_in_time ?? 0).format('HH:mm')} -{' '}
            {moment.unix(timesheet.check_out_time ?? 0).format('HH:mm')}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography sx={{ fontWeight: 500, alignItems: 'center' }} variant='body1'>
            {`${timesheet?.shift_type?.category_name} - ${timesheet?.shift_type?.name}`}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <a onClick={onClick}>
            <img
              width={24}
              height={24}
              src={edit}
              alt='icon update'
              style={{ cursor: 'pointer' }}
            />
          </a>
        </Grid>
      </Grid>
    </Box>
  )
}

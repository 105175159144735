import { createTheme } from '@mui/material/styles'
// A custom theme for this app
const defaultControlHeight = 56

export const Colors = {
  primary: 'rgb(7, 175, 159)',
  secondary: '#2e383d',
  danger: '#e84d48',
  warning: '#f5a623',
  info: '#1aa3ff',
  success: '#417505',
  text: 'rgba(0, 0, 0, 0.87)',
  textSecondary: '#7b7c7e',
  white: 'rgb(255, 255, 255)',
  gray100: '#f1f1f1',
  gray200: 'rgba(0, 0, 0, 0.5)',
  backdropBg: '#F1F1F1',
}

const TitanTheme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.secondary,
    },
    error: {
      main: Colors.danger,
      contrastText: Colors.white,
    },
    warning: {
      main: Colors.warning,
      contrastText: Colors.white,
    },
    info: {
      main: Colors.info,
      contrastText: Colors.white,
    },
    success: {
      main: Colors.success,
      contrastText: Colors.white,
    },

    text: {
      primary: Colors.text,
      secondary: Colors.textSecondary,
    },
    divider: '#e5e5e5',
    background: {
      default: Colors.backdropBg,
    },
    grey: {
      '100': Colors.gray200,
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontSize: 15,
    fontFamily:
      '"Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 15,
      color: Colors.textSecondary,
    },
    caption: {
      fontSize: 13,
    },
    button: {
      fontSize: 20,
      fontWeight: 600,
    },
    h1: {
      fontSize: 60,
    },
    h2: {
      fontSize: 32,
    },
    h3: {
      fontSize: 20,
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: 32,
    },
    subtitle2: {
      fontSize: 24,
    },
  },
  components: {
    MuiSelect: {
      defaultProps: {
        sx: {
          mb: 6,
          '& .MuiSelect-select.MuiOutlinedInput-input': {
            paddingLeft: 2,
            paddingTop: 0,
            paddingBottom: 0,
            height: defaultControlHeight,
            minHeight: defaultControlHeight,
            lineHeight: `${defaultControlHeight}px`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'divider',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'divider',
          },
          '& .MuiInput-input': {
            paddingTop: '5px',
          },
          '& .MuiTypography-root': {
            lineHeight: 'inherit',
          },
          '& .MuiInput-input:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        sx: {
          '&.Dialog-Box': {
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '600px',
                minHeight: '400px',
                px: 10,
                py: 7,
              },
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        sx: {
          '&.MuiButton-sizeMedium': {
            height: defaultControlHeight,
          },
          '&.MuiButton-containedPrimary': {
            color: Colors.text,
            textTransform: 'none',
          },
        },
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          mb: 6,
          '&.searchInput': {
            p: 5,
            pb: 0,
            width: 760,
          },
          '&.searchInput .MuiOutlinedInput-root': {
            borderRadius: 40,
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        sx: {
          mb: 6,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          textTransform: 'uppercase',
          '&.MuiSelect-select': {
            mb: 6,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        sx: {
          p: 0,
          pb: 10,
          textAlign: 'center',
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        sx: {
          p: 0,
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        sx: {
          '&.MuiDialogActions-root': {
            justifyContent: 'center',
            p: 0,
          },
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        sx: {
          ml: 0,
        },
      },
    },
  },
})

export default TitanTheme

/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClockInOut } from '../models';
// @ts-ignore
import { EmployeeTimeSheet } from '../models';
// @ts-ignore
import { EmployeeTimeSheet2 } from '../models';
// @ts-ignore
import { EmployeeTimeSheetNew } from '../models';
// @ts-ignore
import { FormFilterDetailTimeSheetApproveEmployeeView } from '../models';
// @ts-ignore
import { FormFilterDetailTimeSheetApproveGroupView } from '../models';
// @ts-ignore
import { FormFilterListRegistrationApproveByEmployee } from '../models';
// @ts-ignore
import { FormFilterListTimeSheetApproveEmployeeView } from '../models';
// @ts-ignore
import { FormFilterLockTimeSheet } from '../models';
// @ts-ignore
import { FormFilterTimeSheetApproveGroupView } from '../models';
// @ts-ignore
import { RegistrationTime } from '../models';
// @ts-ignore
import { RegistrationTimeToApprove } from '../models';
// @ts-ignore
import { ReponseListTimeSheetMobile } from '../models';
// @ts-ignore
import { SplitTimeSheetUpdate } from '../models';
// @ts-ignore
import { TimeSheetDetail } from '../models';
// @ts-ignore
import { TimeSheetDetail2 } from '../models';
// @ts-ignore
import { TimeSheetDetailUpdate } from '../models';
// @ts-ignore
import { UpdateTimeSheetParams } from '../models';
/**
 * TimeSheetApi - axios parameter creator
 * @export
 */
export const TimeSheetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary approveTimeRegistration
         * @param {Array<RegistrationTimeToApprove>} body approveTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTimeRegistration: async (body: Array<RegistrationTimeToApprove>, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('approveTimeRegistration', 'body', body)
            const localVarPath = `/api/timeregistration/approveTimeRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary approveTimesheetById
         * @param {number} id 
         * @param {TimeSheetDetail} body approveTimesheetById Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTimesheetById: async (id: number, body: TimeSheetDetail, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveTimesheetById', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('approveTimesheetById', 'body', body)
            const localVarPath = `/api/timeregistration/approveTimesheetById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createTimeRegistration
         * @param {Array<ClockInOut>} body createTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeRegistration: async (body: Array<ClockInOut>, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTimeRegistration', 'body', body)
            const localVarPath = `/api/timeregistration/createTimeRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createTimeRegistrationApprove
         * @param {TimeSheetDetailUpdate} body createTimeRegistrationApprove Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeRegistrationApprove: async (body: TimeSheetDetailUpdate, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTimeRegistrationApprove', 'body', body)
            const localVarPath = `/api/timeregistration/createTimeRegistrationApprove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteTimeRegistrationApprove
         * @param {Array<RegistrationTimeToApprove>} body deleteTimeRegistrationApprove Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeRegistrationApprove: async (body: Array<RegistrationTimeToApprove>, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deleteTimeRegistrationApprove', 'body', body)
            const localVarPath = `/api/timeregistration/deleteTimeRegistrationApprove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDetailTimeSheet
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailTimeSheet: async (id: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetailTimeSheet', 'id', id)
            const localVarPath = `/api/timeregistration/getDetailTimeSheet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLastOpenRegistrationTimeApproveByEmployee
         * @param {number} employeeID 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastOpenRegistrationTimeApproveByEmployee: async (employeeID: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeID' is not null or undefined
            assertParamExists('getLastOpenRegistrationTimeApproveByEmployee', 'employeeID', employeeID)
            const localVarPath = `/api/timeregistration/getLastOpenRegistrationTimeApproveByEmployee/{employeeID}`
                .replace(`{${"employeeID"}}`, encodeURIComponent(String(employeeID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListDetailRegistrationApproveEmployeeView
         * @param {FormFilterDetailTimeSheetApproveEmployeeView} body getListDetailRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDetailRegistrationApproveEmployeeView: async (body: FormFilterDetailTimeSheetApproveEmployeeView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListDetailRegistrationApproveEmployeeView', 'body', body)
            const localVarPath = `/api/timeregistration/getListDetailRegistrationApproveEmployeeView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListDetailRegistrationApproveGroupView
         * @param {FormFilterDetailTimeSheetApproveGroupView} body getListDetailRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDetailRegistrationApproveGroupView: async (body: FormFilterDetailTimeSheetApproveGroupView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListDetailRegistrationApproveGroupView', 'body', body)
            const localVarPath = `/api/timeregistration/getListDetailRegistrationApproveGroupView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListRegistrationApproveByEmployee
         * @param {FormFilterListRegistrationApproveByEmployee} body getListRegistrationApproveByEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListRegistrationApproveByEmployee: async (body: FormFilterListRegistrationApproveByEmployee, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListRegistrationApproveByEmployee', 'body', body)
            const localVarPath = `/api/timeregistration/getListRegistrationApproveByEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListRegistrationApproveEmployeeView
         * @param {FormFilterListTimeSheetApproveEmployeeView} body getListDetailRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListRegistrationApproveEmployeeView: async (body: FormFilterListTimeSheetApproveEmployeeView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListRegistrationApproveEmployeeView', 'body', body)
            const localVarPath = `/api/timeregistration/getListRegistrationApproveEmployeeView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListRegistrationApproveGroupView
         * @param {FormFilterTimeSheetApproveGroupView} body getListRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListRegistrationApproveGroupView: async (body: FormFilterTimeSheetApproveGroupView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListRegistrationApproveGroupView', 'body', body)
            const localVarPath = `/api/timeregistration/getListRegistrationApproveGroupView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListTimeSheetApproveGroupView
         * @param {FormFilterTimeSheetApproveGroupView} body getListTimeSheetApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTimeSheetApproveGroupView: async (body: FormFilterTimeSheetApproveGroupView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListTimeSheetApproveGroupView', 'body', body)
            const localVarPath = `/api/timeregistration/getListTimeSheetApproveGroupView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListTimeSheetApproveMobile
         * @param {FormFilterTimeSheetApproveGroupView} body getListTimeSheetApproveMobile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTimeSheetApproveMobile: async (body: FormFilterTimeSheetApproveGroupView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListTimeSheetApproveMobile', 'body', body)
            const localVarPath = `/api/timeregistration/getListTimeSheetApproveMobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListTimeSheetNotClockOut
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTimeSheetNotClockOut: async (minTime: string, maxTime: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListTimeSheetNotClockOut', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListTimeSheetNotClockOut', 'maxTime', maxTime)
            const localVarPath = `/api/timeregistration/getListTimeSheetNotClockOut/{minTime}/{maxTime}`
                .replace(`{${"minTime"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"maxTime"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListTimesheetApproveEmployeeView
         * @param {FormFilterListTimeSheetApproveEmployeeView} body getListTimesheetApproveEmployeeView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTimesheetApproveEmployeeView: async (body: FormFilterListTimeSheetApproveEmployeeView, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListTimesheetApproveEmployeeView', 'body', body)
            const localVarPath = `/api/timeregistration/getListTimesheetApproveEmployeeView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRegistrationApprovedDetailByTimesheetId
         * @param {number} timesheetId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationApprovedDetailByTimesheetId: async (timesheetId: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timesheetId' is not null or undefined
            assertParamExists('getRegistrationApprovedDetailByTimesheetId', 'timesheetId', timesheetId)
            const localVarPath = `/api/timeregistration/getRegistrationApprovedDetailByTimesheetId/{timesheetId}`
                .replace(`{${"timesheetId"}}`, encodeURIComponent(String(timesheetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lockTimeRegistration
         * @param {boolean} isLock 
         * @param {FormFilterLockTimeSheet} body lockTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockTimeRegistration: async (isLock: boolean, body: FormFilterLockTimeSheet, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isLock' is not null or undefined
            assertParamExists('lockTimeRegistration', 'isLock', isLock)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lockTimeRegistration', 'body', body)
            const localVarPath = `/api/timeregistration/lockTimeRegistration/{isLock}`
                .replace(`{${"isLock"}}`, encodeURIComponent(String(isLock)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary splitTimeSheet
         * @param {number} id 
         * @param {Array<SplitTimeSheetUpdate>} body splitTimeSheet Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        splitTimeSheet: async (id: number, body: Array<SplitTimeSheetUpdate>, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('splitTimeSheet', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('splitTimeSheet', 'body', body)
            const localVarPath = `/api/timeregistration/splitTimeSheet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unApproveTimeRegistration
         * @param {Array<RegistrationTimeToApprove>} body unApproveTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unApproveTimeRegistration: async (body: Array<RegistrationTimeToApprove>, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('unApproveTimeRegistration', 'body', body)
            const localVarPath = `/api/timeregistration/unApproveTimeRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unApproveTimesheetById
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unApproveTimesheetById: async (id: number, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unApproveTimesheetById', 'id', id)
            const localVarPath = `/api/timeregistration/unApproveTimesheetById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateTimeRegistration
         * @param {Array<TimeSheetDetailUpdate>} body updateTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeRegistration: async (body: Array<TimeSheetDetailUpdate>, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTimeRegistration', 'body', body)
            const localVarPath = `/api/timeregistration/updateTimeRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateTimeSheet
         * @param {number} id 
         * @param {UpdateTimeSheetParams} body updateTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeSheet: async (id: number, body: UpdateTimeSheetParams, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTimeSheet', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTimeSheet', 'body', body)
            const localVarPath = `/api/timeregistration/updateTimeSheet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeSheetApi - functional programming interface
 * @export
 */
export const TimeSheetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeSheetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary approveTimeRegistration
         * @param {Array<RegistrationTimeToApprove>} body approveTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveTimeRegistration(body: Array<RegistrationTimeToApprove>, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSheetDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveTimeRegistration(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary approveTimesheetById
         * @param {number} id 
         * @param {TimeSheetDetail} body approveTimesheetById Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveTimesheetById(id: number, body: TimeSheetDetail, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSheetDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveTimesheetById(id, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createTimeRegistration
         * @param {Array<ClockInOut>} body createTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTimeRegistration(body: Array<ClockInOut>, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTimeRegistration(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary createTimeRegistrationApprove
         * @param {TimeSheetDetailUpdate} body createTimeRegistrationApprove Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTimeRegistrationApprove(body: TimeSheetDetailUpdate, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSheetDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTimeRegistrationApprove(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteTimeRegistrationApprove
         * @param {Array<RegistrationTimeToApprove>} body deleteTimeRegistrationApprove Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimeRegistrationApprove(body: Array<RegistrationTimeToApprove>, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimeRegistrationApprove(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDetailTimeSheet
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailTimeSheet(id: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSheetDetail2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailTimeSheet(id, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getLastOpenRegistrationTimeApproveByEmployee
         * @param {number} employeeID 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastOpenRegistrationTimeApproveByEmployee(employeeID: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSheetDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastOpenRegistrationTimeApproveByEmployee(employeeID, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListDetailRegistrationApproveEmployeeView
         * @param {FormFilterDetailTimeSheetApproveEmployeeView} body getListDetailRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListDetailRegistrationApproveEmployeeView(body: FormFilterDetailTimeSheetApproveEmployeeView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSheetDetail2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListDetailRegistrationApproveEmployeeView(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListDetailRegistrationApproveGroupView
         * @param {FormFilterDetailTimeSheetApproveGroupView} body getListDetailRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListDetailRegistrationApproveGroupView(body: FormFilterDetailTimeSheetApproveGroupView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSheetDetail2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListDetailRegistrationApproveGroupView(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListRegistrationApproveByEmployee
         * @param {FormFilterListRegistrationApproveByEmployee} body getListRegistrationApproveByEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListRegistrationApproveByEmployee(body: FormFilterListRegistrationApproveByEmployee, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeTimeSheet2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListRegistrationApproveByEmployee(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListRegistrationApproveEmployeeView
         * @param {FormFilterListTimeSheetApproveEmployeeView} body getListDetailRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListRegistrationApproveEmployeeView(body: FormFilterListTimeSheetApproveEmployeeView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeTimeSheet2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListRegistrationApproveEmployeeView(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListRegistrationApproveGroupView
         * @param {FormFilterTimeSheetApproveGroupView} body getListRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListRegistrationApproveGroupView(body: FormFilterTimeSheetApproveGroupView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeTimeSheet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListRegistrationApproveGroupView(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListTimeSheetApproveGroupView
         * @param {FormFilterTimeSheetApproveGroupView} body getListTimeSheetApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTimeSheetApproveGroupView(body: FormFilterTimeSheetApproveGroupView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeTimeSheetNew>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTimeSheetApproveGroupView(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListTimeSheetApproveMobile
         * @param {FormFilterTimeSheetApproveGroupView} body getListTimeSheetApproveMobile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTimeSheetApproveMobile(body: FormFilterTimeSheetApproveGroupView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReponseListTimeSheetMobile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTimeSheetApproveMobile(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListTimeSheetNotClockOut
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTimeSheetNotClockOut(minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTimeSheetNotClockOut(minTime, maxTime, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getListTimesheetApproveEmployeeView
         * @param {FormFilterListTimeSheetApproveEmployeeView} body getListTimesheetApproveEmployeeView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTimesheetApproveEmployeeView(body: FormFilterListTimeSheetApproveEmployeeView, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReponseListTimeSheetMobile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTimesheetApproveEmployeeView(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getRegistrationApprovedDetailByTimesheetId
         * @param {number} timesheetId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationApprovedDetailByTimesheetId(timesheetId: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSheetDetail2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistrationApprovedDetailByTimesheetId(timesheetId, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lockTimeRegistration
         * @param {boolean} isLock 
         * @param {FormFilterLockTimeSheet} body lockTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockTimeRegistration(isLock: boolean, body: FormFilterLockTimeSheet, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegistrationTime>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockTimeRegistration(isLock, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary splitTimeSheet
         * @param {number} id 
         * @param {Array<SplitTimeSheetUpdate>} body splitTimeSheet Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async splitTimeSheet(id: number, body: Array<SplitTimeSheetUpdate>, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationTimeToApprove>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.splitTimeSheet(id, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary unApproveTimeRegistration
         * @param {Array<RegistrationTimeToApprove>} body unApproveTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unApproveTimeRegistration(body: Array<RegistrationTimeToApprove>, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unApproveTimeRegistration(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary unApproveTimesheetById
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unApproveTimesheetById(id: number, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unApproveTimesheetById(id, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateTimeRegistration
         * @param {Array<TimeSheetDetailUpdate>} body updateTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeRegistration(body: Array<TimeSheetDetailUpdate>, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSheetDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeRegistration(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateTimeSheet
         * @param {number} id 
         * @param {UpdateTimeSheetParams} body updateTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeSheet(id: number, body: UpdateTimeSheetParams, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationTimeToApprove>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeSheet(id, body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimeSheetApi - factory interface
 * @export
 */
export const TimeSheetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeSheetApiFp(configuration)
    return {
        /**
         * 
         * @summary approveTimeRegistration
         * @param {Array<RegistrationTimeToApprove>} body approveTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTimeRegistration(body: Array<RegistrationTimeToApprove>, source?: string, options?: any): AxiosPromise<Array<TimeSheetDetail>> {
            return localVarFp.approveTimeRegistration(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary approveTimesheetById
         * @param {number} id 
         * @param {TimeSheetDetail} body approveTimesheetById Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTimesheetById(id: number, body: TimeSheetDetail, source?: string, options?: any): AxiosPromise<TimeSheetDetail> {
            return localVarFp.approveTimesheetById(id, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createTimeRegistration
         * @param {Array<ClockInOut>} body createTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeRegistration(body: Array<ClockInOut>, source?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.createTimeRegistration(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createTimeRegistrationApprove
         * @param {TimeSheetDetailUpdate} body createTimeRegistrationApprove Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeRegistrationApprove(body: TimeSheetDetailUpdate, source?: string, options?: any): AxiosPromise<TimeSheetDetail> {
            return localVarFp.createTimeRegistrationApprove(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteTimeRegistrationApprove
         * @param {Array<RegistrationTimeToApprove>} body deleteTimeRegistrationApprove Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeRegistrationApprove(body: Array<RegistrationTimeToApprove>, source?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.deleteTimeRegistrationApprove(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDetailTimeSheet
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailTimeSheet(id: number, source?: string, options?: any): AxiosPromise<TimeSheetDetail2> {
            return localVarFp.getDetailTimeSheet(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getLastOpenRegistrationTimeApproveByEmployee
         * @param {number} employeeID 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastOpenRegistrationTimeApproveByEmployee(employeeID: number, source?: string, options?: any): AxiosPromise<TimeSheetDetail> {
            return localVarFp.getLastOpenRegistrationTimeApproveByEmployee(employeeID, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListDetailRegistrationApproveEmployeeView
         * @param {FormFilterDetailTimeSheetApproveEmployeeView} body getListDetailRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDetailRegistrationApproveEmployeeView(body: FormFilterDetailTimeSheetApproveEmployeeView, source?: string, options?: any): AxiosPromise<Array<TimeSheetDetail2>> {
            return localVarFp.getListDetailRegistrationApproveEmployeeView(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListDetailRegistrationApproveGroupView
         * @param {FormFilterDetailTimeSheetApproveGroupView} body getListDetailRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListDetailRegistrationApproveGroupView(body: FormFilterDetailTimeSheetApproveGroupView, source?: string, options?: any): AxiosPromise<Array<TimeSheetDetail2>> {
            return localVarFp.getListDetailRegistrationApproveGroupView(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListRegistrationApproveByEmployee
         * @param {FormFilterListRegistrationApproveByEmployee} body getListRegistrationApproveByEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListRegistrationApproveByEmployee(body: FormFilterListRegistrationApproveByEmployee, source?: string, options?: any): AxiosPromise<EmployeeTimeSheet2> {
            return localVarFp.getListRegistrationApproveByEmployee(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListRegistrationApproveEmployeeView
         * @param {FormFilterListTimeSheetApproveEmployeeView} body getListDetailRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListRegistrationApproveEmployeeView(body: FormFilterListTimeSheetApproveEmployeeView, source?: string, options?: any): AxiosPromise<Array<EmployeeTimeSheet2>> {
            return localVarFp.getListRegistrationApproveEmployeeView(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListRegistrationApproveGroupView
         * @param {FormFilterTimeSheetApproveGroupView} body getListRegistrationApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListRegistrationApproveGroupView(body: FormFilterTimeSheetApproveGroupView, source?: string, options?: any): AxiosPromise<Array<EmployeeTimeSheet>> {
            return localVarFp.getListRegistrationApproveGroupView(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListTimeSheetApproveGroupView
         * @param {FormFilterTimeSheetApproveGroupView} body getListTimeSheetApproveGroupView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTimeSheetApproveGroupView(body: FormFilterTimeSheetApproveGroupView, source?: string, options?: any): AxiosPromise<Array<EmployeeTimeSheetNew>> {
            return localVarFp.getListTimeSheetApproveGroupView(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListTimeSheetApproveMobile
         * @param {FormFilterTimeSheetApproveGroupView} body getListTimeSheetApproveMobile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTimeSheetApproveMobile(body: FormFilterTimeSheetApproveGroupView, source?: string, options?: any): AxiosPromise<ReponseListTimeSheetMobile> {
            return localVarFp.getListTimeSheetApproveMobile(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListTimeSheetNotClockOut
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTimeSheetNotClockOut(minTime: string, maxTime: string, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getListTimeSheetNotClockOut(minTime, maxTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListTimesheetApproveEmployeeView
         * @param {FormFilterListTimeSheetApproveEmployeeView} body getListTimesheetApproveEmployeeView Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTimesheetApproveEmployeeView(body: FormFilterListTimeSheetApproveEmployeeView, source?: string, options?: any): AxiosPromise<ReponseListTimeSheetMobile> {
            return localVarFp.getListTimesheetApproveEmployeeView(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getRegistrationApprovedDetailByTimesheetId
         * @param {number} timesheetId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationApprovedDetailByTimesheetId(timesheetId: number, source?: string, options?: any): AxiosPromise<Array<TimeSheetDetail2>> {
            return localVarFp.getRegistrationApprovedDetailByTimesheetId(timesheetId, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lockTimeRegistration
         * @param {boolean} isLock 
         * @param {FormFilterLockTimeSheet} body lockTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockTimeRegistration(isLock: boolean, body: FormFilterLockTimeSheet, source?: string, options?: any): AxiosPromise<Array<RegistrationTime>> {
            return localVarFp.lockTimeRegistration(isLock, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary splitTimeSheet
         * @param {number} id 
         * @param {Array<SplitTimeSheetUpdate>} body splitTimeSheet Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        splitTimeSheet(id: number, body: Array<SplitTimeSheetUpdate>, source?: string, options?: any): AxiosPromise<RegistrationTimeToApprove> {
            return localVarFp.splitTimeSheet(id, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary unApproveTimeRegistration
         * @param {Array<RegistrationTimeToApprove>} body unApproveTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unApproveTimeRegistration(body: Array<RegistrationTimeToApprove>, source?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.unApproveTimeRegistration(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary unApproveTimesheetById
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unApproveTimesheetById(id: number, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.unApproveTimesheetById(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateTimeRegistration
         * @param {Array<TimeSheetDetailUpdate>} body updateTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeRegistration(body: Array<TimeSheetDetailUpdate>, source?: string, options?: any): AxiosPromise<TimeSheetDetail> {
            return localVarFp.updateTimeRegistration(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateTimeSheet
         * @param {number} id 
         * @param {UpdateTimeSheetParams} body updateTimeRegistration Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeSheet(id: number, body: UpdateTimeSheetParams, source?: string, options?: any): AxiosPromise<RegistrationTimeToApprove> {
            return localVarFp.updateTimeSheet(id, body, source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimeSheetApi - object-oriented interface
 * @export
 * @class TimeSheetApi
 * @extends {BaseAPI}
 */
export class TimeSheetApi extends BaseAPI {
    /**
     * 
     * @summary approveTimeRegistration
     * @param {Array<RegistrationTimeToApprove>} body approveTimeRegistration Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public approveTimeRegistration(body: Array<RegistrationTimeToApprove>, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).approveTimeRegistration(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary approveTimesheetById
     * @param {number} id 
     * @param {TimeSheetDetail} body approveTimesheetById Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public approveTimesheetById(id: number, body: TimeSheetDetail, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).approveTimesheetById(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createTimeRegistration
     * @param {Array<ClockInOut>} body createTimeRegistration Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public createTimeRegistration(body: Array<ClockInOut>, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).createTimeRegistration(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createTimeRegistrationApprove
     * @param {TimeSheetDetailUpdate} body createTimeRegistrationApprove Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public createTimeRegistrationApprove(body: TimeSheetDetailUpdate, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).createTimeRegistrationApprove(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteTimeRegistrationApprove
     * @param {Array<RegistrationTimeToApprove>} body deleteTimeRegistrationApprove Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public deleteTimeRegistrationApprove(body: Array<RegistrationTimeToApprove>, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).deleteTimeRegistrationApprove(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDetailTimeSheet
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getDetailTimeSheet(id: number, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getDetailTimeSheet(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getLastOpenRegistrationTimeApproveByEmployee
     * @param {number} employeeID 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getLastOpenRegistrationTimeApproveByEmployee(employeeID: number, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getLastOpenRegistrationTimeApproveByEmployee(employeeID, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListDetailRegistrationApproveEmployeeView
     * @param {FormFilterDetailTimeSheetApproveEmployeeView} body getListDetailRegistrationApproveGroupView Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getListDetailRegistrationApproveEmployeeView(body: FormFilterDetailTimeSheetApproveEmployeeView, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getListDetailRegistrationApproveEmployeeView(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListDetailRegistrationApproveGroupView
     * @param {FormFilterDetailTimeSheetApproveGroupView} body getListDetailRegistrationApproveGroupView Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getListDetailRegistrationApproveGroupView(body: FormFilterDetailTimeSheetApproveGroupView, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getListDetailRegistrationApproveGroupView(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListRegistrationApproveByEmployee
     * @param {FormFilterListRegistrationApproveByEmployee} body getListRegistrationApproveByEmployee Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getListRegistrationApproveByEmployee(body: FormFilterListRegistrationApproveByEmployee, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getListRegistrationApproveByEmployee(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListRegistrationApproveEmployeeView
     * @param {FormFilterListTimeSheetApproveEmployeeView} body getListDetailRegistrationApproveGroupView Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getListRegistrationApproveEmployeeView(body: FormFilterListTimeSheetApproveEmployeeView, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getListRegistrationApproveEmployeeView(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListRegistrationApproveGroupView
     * @param {FormFilterTimeSheetApproveGroupView} body getListRegistrationApproveGroupView Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getListRegistrationApproveGroupView(body: FormFilterTimeSheetApproveGroupView, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getListRegistrationApproveGroupView(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListTimeSheetApproveGroupView
     * @param {FormFilterTimeSheetApproveGroupView} body getListTimeSheetApproveGroupView Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getListTimeSheetApproveGroupView(body: FormFilterTimeSheetApproveGroupView, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getListTimeSheetApproveGroupView(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListTimeSheetApproveMobile
     * @param {FormFilterTimeSheetApproveGroupView} body getListTimeSheetApproveMobile Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getListTimeSheetApproveMobile(body: FormFilterTimeSheetApproveGroupView, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getListTimeSheetApproveMobile(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListTimeSheetNotClockOut
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getListTimeSheetNotClockOut(minTime: string, maxTime: string, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getListTimeSheetNotClockOut(minTime, maxTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListTimesheetApproveEmployeeView
     * @param {FormFilterListTimeSheetApproveEmployeeView} body getListTimesheetApproveEmployeeView Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getListTimesheetApproveEmployeeView(body: FormFilterListTimeSheetApproveEmployeeView, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getListTimesheetApproveEmployeeView(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getRegistrationApprovedDetailByTimesheetId
     * @param {number} timesheetId 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public getRegistrationApprovedDetailByTimesheetId(timesheetId: number, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).getRegistrationApprovedDetailByTimesheetId(timesheetId, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lockTimeRegistration
     * @param {boolean} isLock 
     * @param {FormFilterLockTimeSheet} body lockTimeRegistration Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public lockTimeRegistration(isLock: boolean, body: FormFilterLockTimeSheet, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).lockTimeRegistration(isLock, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary splitTimeSheet
     * @param {number} id 
     * @param {Array<SplitTimeSheetUpdate>} body splitTimeSheet Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public splitTimeSheet(id: number, body: Array<SplitTimeSheetUpdate>, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).splitTimeSheet(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary unApproveTimeRegistration
     * @param {Array<RegistrationTimeToApprove>} body unApproveTimeRegistration Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public unApproveTimeRegistration(body: Array<RegistrationTimeToApprove>, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).unApproveTimeRegistration(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary unApproveTimesheetById
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public unApproveTimesheetById(id: number, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).unApproveTimesheetById(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateTimeRegistration
     * @param {Array<TimeSheetDetailUpdate>} body updateTimeRegistration Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public updateTimeRegistration(body: Array<TimeSheetDetailUpdate>, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).updateTimeRegistration(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateTimeSheet
     * @param {number} id 
     * @param {UpdateTimeSheetParams} body updateTimeRegistration Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSheetApi
     */
    public updateTimeSheet(id: number, body: UpdateTimeSheetParams, source?: string, options?: AxiosRequestConfig) {
        return TimeSheetApiFp(this.configuration).updateTimeSheet(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }
}

import * as React from 'react'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { FormFilterClockApp } from '../../Services/Swagger'

interface SearchProps {
  onEnter: (searchOnEnter: FormFilterClockApp) => void
}

const Search = require('../../../src/Assets/Images/search.svg').default

export default function InputWithIcon({ onEnter }: SearchProps) {
  const [search, setSearch] = React.useState<string>('')
  const changeSearch = (event: any) => {
    setSearch(event.target.value)
  }
  const searchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onEnter(event.target.value)
    }
  }

  return (
    <Box sx={{ textAlign: 'center' }}>
      <TextField
        onChange={changeSearch}
        onKeyDown={searchKeyDown}
        value={search}
        className='searchInput'
        autoFocus
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <img width={24} height={24} src={Search} alt='icon search' />
            </InputAdornment>
          ),
        }}
        variant='outlined'
      />
    </Box>
  )
}

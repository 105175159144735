import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Avatar, CardActionArea } from '@mui/material'
import { ClockAppResponse } from '../../Services/Swagger'
import { CompanyHelper } from '../../Helpers'

interface Props {
  user: ClockAppResponse
  onClick: (data: any) => void
}

export default function UserCard({ user, onClick }: Props) {
  const userName = new CompanyHelper().stringAvatar(user.fullname)
  const workStatus = new CompanyHelper().getWorkStatus(user.working_status)

  return (
    <Card
      sx={{
        display: 'flex',
        height: 240,
        boxShadow: 'none',
        borderRadius: 2,
        width: 200,
        border: 1,
        borderColor: 'rgba(0, 0, 0, 0.1)',
      }}
      onClick={() => onClick(user)}
    >
      <CardActionArea sx={{ textAlign: 'center' }}>
        <Avatar
          sx={{
            mx: 'auto',
            height: 64,
            width: 64,
            backgroundColor: `${user.color}`,
          }}
          {...userName}
        />
        <CardContent>
          <Typography gutterBottom variant='body1' component='div' sx={{ fontWeight: 500 }}>
            {user.fullname}
          </Typography>
          <Typography variant='caption' color='text.secondary'>
            {workStatus}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

import globalAxios from 'axios'
import Storage from './Storage'

globalAxios.defaults.baseURL = process.env.REACT_APP_API_URL

const requestHandler = async (request: any) => {
  const token: any = Storage.getItem('APP_TOKEN')
  if (token) {
    request.headers.token = token
    request.headers.app = 'TimeClockHorsens'
    request.headers.source = 'mobile'
    request.headers.skipAcl = true
  }
  return request
}

globalAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (requestError) => {
    return Promise.reject(requestError)
  },
)

globalAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

import React, { Suspense, useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import './App.scss'
import { CheckIp, Home, Root } from './Containers'
import globalAxios from 'axios'

function App() {
  useEffect(() => {
    globalAxios.defaults.baseURL = process.env.REACT_APP_API_URL
  })
  return (
    <main className='App'>
      <Suspense fallback={null}>
        <Routes>
          <Route path='/' element={<Root />}>
            <Route path='' element={<CheckIp />} />
            <Route path='/company' element={<Home />} />
          </Route>
        </Routes>
        <Outlet />
      </Suspense>
    </main>
  )
}

export default App

import { ListSubheader, MenuItem, Select, SelectProps } from '@mui/material'
import { TimeBudgetApi } from '../../Services/Swagger'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export default function WorkTypeSelect({ onChange, value, ...otherProps }: SelectProps) {
  const { data } = useQuery(['/api/time_budget/getListShiftTypes'], async () => {
    const response = await new TimeBudgetApi().getListShiftTypes()
    return response.data
  })
  const [options, setOptions] = useState<any[]>([])
  useEffect(() => {
    if (data) {
      const newOptions: any[] = []
      data.map((shiftTypeCat) => {
        if (shiftTypeCat.allow_time_clock) {
          newOptions.push(
            <ListSubheader key={shiftTypeCat.shift_type_category_id} sx={{ color: 'primary.main' }}>
              {shiftTypeCat.name}
            </ListSubheader>,
          )
          shiftTypeCat.shift_types?.map((shiftType) => {
            newOptions.push(
              <MenuItem
                key={`shiftType-${shiftType.shift_type_id}`}
                value={shiftType.shift_type_id}
              >
                {shiftType.name}
              </MenuItem>,
            )
          })
        }
      })
      setOptions(newOptions)
    }
  }, [data])

  return (
    <Select fullWidth value={value} onChange={onChange} {...otherProps}>
      {options &&
        options.map((element) => {
          return element
        })}
    </Select>
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClockAppResponse } from '../models';
// @ts-ignore
import { DepartmentDetail } from '../models';
// @ts-ignore
import { DetailClockApp } from '../models';
// @ts-ignore
import { FormCheckCompanyIP } from '../models';
// @ts-ignore
import { FormFilterClockApp } from '../models';
// @ts-ignore
import { FormVerifyEmployeeClockAppp } from '../models';
// @ts-ignore
import { ReponseVerifyEmployeeClockApp } from '../models';
// @ts-ignore
import { ResponseCheckCompanyIP } from '../models';
/**
 * ClockappsApi - axios parameter creator
 * @export
 */
export const ClockappsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary checkCompanyIP
         * @param {FormCheckCompanyIP} body checkCompanyIP
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCompanyIP: async (body: FormCheckCompanyIP, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('checkCompanyIP', 'body', body)
            const localVarPath = `/api/clockapp/checkCompanyIP`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DetailClockApp
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailClockApp: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clockapp/detail-clock-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDepartmentClockApp
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartmentClockApp: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clockapp/getAllDepartmentClockApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary myDataClockApp
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myDataClockApp: async (source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clockapp/myDataClockApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchDataClockApp
         * @param {FormFilterClockApp} body searchDataClockApp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDataClockApp: async (body: FormFilterClockApp, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('searchDataClockApp', 'body', body)
            const localVarPath = `/api/clockapp/searchDataClockApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verifyEmployeeClockApp
         * @param {FormVerifyEmployeeClockAppp} body verifyEmployeeClockApp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmployeeClockApp: async (body: FormVerifyEmployeeClockAppp, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('verifyEmployeeClockApp', 'body', body)
            const localVarPath = `/api/clockapp/verifyEmployeeClockApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source !== undefined && source !== null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClockappsApi - functional programming interface
 * @export
 */
export const ClockappsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClockappsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary checkCompanyIP
         * @param {FormCheckCompanyIP} body checkCompanyIP
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCompanyIP(body: FormCheckCompanyIP, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseCheckCompanyIP>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkCompanyIP(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DetailClockApp
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailClockApp(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailClockApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailClockApp(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getAllDepartmentClockApp
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDepartmentClockApp(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDepartmentClockApp(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary myDataClockApp
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myDataClockApp(source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClockAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myDataClockApp(source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary searchDataClockApp
         * @param {FormFilterClockApp} body searchDataClockApp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDataClockApp(body: FormFilterClockApp, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClockAppResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDataClockApp(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary verifyEmployeeClockApp
         * @param {FormVerifyEmployeeClockAppp} body verifyEmployeeClockApp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmployeeClockApp(body: FormVerifyEmployeeClockAppp, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReponseVerifyEmployeeClockApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmployeeClockApp(body, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClockappsApi - factory interface
 * @export
 */
export const ClockappsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClockappsApiFp(configuration)
    return {
        /**
         * 
         * @summary checkCompanyIP
         * @param {FormCheckCompanyIP} body checkCompanyIP
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCompanyIP(body: FormCheckCompanyIP, source?: string, options?: any): AxiosPromise<Array<ResponseCheckCompanyIP>> {
            return localVarFp.checkCompanyIP(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DetailClockApp
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailClockApp(source?: string, options?: any): AxiosPromise<DetailClockApp> {
            return localVarFp.detailClockApp(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllDepartmentClockApp
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartmentClockApp(source?: string, options?: any): AxiosPromise<Array<DepartmentDetail>> {
            return localVarFp.getAllDepartmentClockApp(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary myDataClockApp
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myDataClockApp(source?: string, options?: any): AxiosPromise<ClockAppResponse> {
            return localVarFp.myDataClockApp(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchDataClockApp
         * @param {FormFilterClockApp} body searchDataClockApp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDataClockApp(body: FormFilterClockApp, source?: string, options?: any): AxiosPromise<Array<ClockAppResponse>> {
            return localVarFp.searchDataClockApp(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary verifyEmployeeClockApp
         * @param {FormVerifyEmployeeClockAppp} body verifyEmployeeClockApp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmployeeClockApp(body: FormVerifyEmployeeClockAppp, source?: string, options?: any): AxiosPromise<ReponseVerifyEmployeeClockApp> {
            return localVarFp.verifyEmployeeClockApp(body, source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClockappsApi - object-oriented interface
 * @export
 * @class ClockappsApi
 * @extends {BaseAPI}
 */
export class ClockappsApi extends BaseAPI {
    /**
     * 
     * @summary checkCompanyIP
     * @param {FormCheckCompanyIP} body checkCompanyIP
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockappsApi
     */
    public checkCompanyIP(body: FormCheckCompanyIP, source?: string, options?: AxiosRequestConfig) {
        return ClockappsApiFp(this.configuration).checkCompanyIP(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DetailClockApp
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockappsApi
     */
    public detailClockApp(source?: string, options?: AxiosRequestConfig) {
        return ClockappsApiFp(this.configuration).detailClockApp(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllDepartmentClockApp
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockappsApi
     */
    public getAllDepartmentClockApp(source?: string, options?: AxiosRequestConfig) {
        return ClockappsApiFp(this.configuration).getAllDepartmentClockApp(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary myDataClockApp
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockappsApi
     */
    public myDataClockApp(source?: string, options?: AxiosRequestConfig) {
        return ClockappsApiFp(this.configuration).myDataClockApp(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchDataClockApp
     * @param {FormFilterClockApp} body searchDataClockApp Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockappsApi
     */
    public searchDataClockApp(body: FormFilterClockApp, source?: string, options?: AxiosRequestConfig) {
        return ClockappsApiFp(this.configuration).searchDataClockApp(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary verifyEmployeeClockApp
     * @param {FormVerifyEmployeeClockAppp} body verifyEmployeeClockApp Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClockappsApi
     */
    public verifyEmployeeClockApp(body: FormVerifyEmployeeClockAppp, source?: string, options?: AxiosRequestConfig) {
        return ClockappsApiFp(this.configuration).verifyEmployeeClockApp(body, source, options).then((request) => request(this.axios, this.basePath));
    }
}

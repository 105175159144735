import React from 'react'
import Dialog from '@mui/material/Dialog'
import {
  ClockAppResponse,
  ReponseVerifyEmployeeClockApp,
  TimeSheetApi,
  TimeSheetDetailUpdate,
} from '../../../Services/Swagger'
import { VerifyPassword, ClockInOut, TimeSheets } from '../../../Components'
import Storage from '../../../Services/Storage'
import { ClockOutResponse } from '../../../Components/ClockInOut/ClockInOut'
import { useTranslation } from 'react-i18next'

interface OpenProps {
  isOpen: boolean
  onClose: (yes?: boolean) => void
  employee?: ClockAppResponse
}

const closeIcon = require('../../../Assets/Images/close.svg').default

interface ListScreens {
  VerifyEmployee: undefined
  ClockIn: undefined
  ClockOut: undefined
  ListTimesheet: undefined
  EditTimesheet: undefined
}
export default function Verification({ isOpen, employee, onClose }: OpenProps) {
  const { t } = useTranslation()
  const [step, setStep] = React.useState<keyof ListScreens>('VerifyEmployee')

  const onSuccess = (employeeProfile: ReponseVerifyEmployeeClockApp) => {
    const registration = employee?.lastRegistrationTime?.registration_time_approve_id
    Storage.setItem('APP_TOKEN', employeeProfile.token)
    if (registration === 0) {
      setStep('ClockIn')
    } else {
      setStep('ClockOut')
    }
  }
  const onClockInOut = async (values: ClockOutResponse) => {
    const payload: TimeSheetDetailUpdate = {
      check_in_time: employee?.lastRegistrationTime?.check_in_time,
      check_out_time: employee?.lastRegistrationTime?.check_out_time,
      shift_type_id: values.shift_type_id,
      checkin_note: '',
      employee_id: employee?.employee_id,
      group_id: employee?.group?.group_id,
    }
    try {
      if (step === 'ClockIn') {
        payload.check_in_time = values.unix
        await new TimeSheetApi().createTimeRegistrationApprove(payload)
      }
      if (step === 'ClockOut') {
        payload.check_out_time = values.unix
        await new TimeSheetApi().updateTimeSheet(
          employee?.lastRegistrationTime?.registration_time_approve_id ?? 0,
          payload,
        )
      }
      onClose()
    } catch (ex: any) {
      alert(t(ex.response.statusText))
    }
  }
  return (
    <>
      <Dialog open={isOpen} onClose={() => onClose()} className='Dialog-Box'>
        <img
          src={closeIcon}
          alt='close'
          onClick={() => onClose()}
          height={20}
          style={{ position: 'absolute', left: '94%', top: '3%', cursor: 'pointer' }}
        />
        {step === 'VerifyEmployee' && <VerifyPassword employee={employee} onSuccess={onSuccess} />}
        {(step === 'ClockIn' || step === 'ClockOut') && (
          <ClockInOut
            type={step}
            employee={employee}
            onSuccess={onClockInOut}
            onClickTimeSheet={() => setStep('ListTimesheet')}
          />
        )}
        {step === 'ListTimesheet' && <TimeSheets employee={employee} />}
      </Dialog>
    </>
  )
}

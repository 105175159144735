import * as React from 'react'
import Box from '@mui/material/Box'
import { Clock } from '../Clock/Clock'
export default function ButtonAppBar() {
  return (
    <Box sx={{ bgcolor: 'primary.main', pt: 8 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingBottom: 5,
        }}
      >
        <Clock />
      </Box>
    </Box>
  )
}
